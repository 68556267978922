<template>
  <!-- scan/scan START -->
  <div class="scan-terminal">
    <!-- qr-code:icon START -->
    <div class="icon">
      <img width="64" :src="iconTerminal" />
    </div>
    <!-- qr-code:icon END -->

    <h1 v-html="$t('qrcode.scanqrcode')"></h1>

    <!-- qr-code:description START -->
    <div class="description thin">
      <p v-html="$t('terminal.scannen')"></p>
    </div>
    <!-- qr-code:description START -->

    <!-- qr-code:code START -->
    <div class="code">
      <router-link
        :to="'/scan/terminal/terminal/terminal'"
      >
        <img v-if="iconCode" :src="iconCode" />
        <span
          class="f-like-image-loading"
          v-if="!iconCode"
        ></span>
      </router-link>
    </div>
    <!-- qr-code:code END -->
  </div>
  <!-- scan/scan END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

import ICONS from '../../../utils/icons';

const {
  iTerminal,
} = ICONS;

export default {
  name: 'scan-terminal',
  mixins: [titleMixin],
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('cart.paynow'),

      /**
       * QR Code Icon
       */
      qrcode: '',

      /**
       * Terminal Icon
       */
      iconTerminal: localStorage && localStorage.getItem('theme') === 'light' ? iTerminal.defaultUrl : iTerminal.greenUrl,
    };
  },
  async created() {
    this.qrcode = await this.FETCH_QRCODE();
  },
  computed: {
    iconCode() {
      return `${this.qrcode}`;
    },
  },
  methods: {
    async FETCH_QRCODE() {
      await this.$store.dispatch('setQrcode');
      return this.$store.getters.qrcode;
    },
  },
};
</script>
